import { DATE_FORMAT } from "@/common/const/common";
import { formSkippedFields } from "./const";

export const getQueryFilters = async (filters, dayjs) => {
  const queryFilters = [];
  Object.keys(filters).forEach((key) => {
    if (key === "DateStart" && null !== filters[key]) {
      const date = dayjs(filters[key]).format(DATE_FORMAT);
      queryFilters.push(`${key}_gte=${date}`);
    }

    if (key === "DateFinish" && null !== filters[key]) {
      const date = dayjs(filters[key]).format(DATE_FORMAT);
      queryFilters.push(`${key}_lte=${date}`);
    }

    if (filters[key]?.length >= 3) {
      if (key === "category_sports") {
        queryFilters.push(`${key}.Title_contains=${filters[key]}`);
      } else {
        queryFilters.push(`${key}_contains=${filters[key]}`);
      }
    }
  });

  return queryFilters;
};

export const getTeamQueryFilters = async (filters) => {
  const queryFilters = [];
  Object.keys(filters).forEach((key) => {
    if (filters[key]?.length < 3) {
      return queryFilters;
    }

    if (key === "category_sports") {
      queryFilters.push(`${key}.Title_contains=${filters[key]}`);
    } else if (key === "region_ru") {
      queryFilters.push(`${key}.name_contains=${filters[key]}`);
    } else {
      queryFilters.push(`${key}_contains=${filters[key]}`);
    }
  });

  return queryFilters;
};

export const getResultsQueryFilters = async (filters, dayjs) => {
  const queryFilters = [];
  Object.keys(filters).forEach((key) => {
    if (key === "date" && null !== filters[key]) {
      const date = dayjs(filters[key]).format(DATE_FORMAT);
      queryFilters.push(`${key}_eq=${date}`);
    }

    if (key === "place") {
      const value = filters[key];
      if (+value >= 1) {
        queryFilters.push(`${key}_eq=${filters[key]}`);
      }
    }

    if (filters[key]?.length >= 3) {
      queryFilters.push(`${key}_contains=${filters[key]}`);
    }
  });

  return queryFilters;
};

export const getFormData = (event, file) => {
  const formData = new FormData();
  const data = {};

  Object.keys(event).forEach((key) => {
    if (!formSkippedFields.includes(key)) {
      data[key] = event[key];
    }
  });

  if (file && file instanceof Blob && file !== event.Images[0]) {
    formData.append("files.Images", file, file.name);
  }
  formData.append("data", JSON.stringify(data));

  return formData;
};

export const getRowClass = (row) => {
  return row.country.name.includes("Россия")
    ? "has-background-info has-text-white"
    : "";
};

export const sortFirstPlace = (a, b, isAsc) => {
  return isAsc ? +a.firstPlace - +b.firstPlace : +b.firstPlace - +a.firstPlace;
};

export const sortSecondPlace = (a, b, isAsc) => {
  return isAsc
    ? +a.secondPlace - +b.a.secondPlace
    : +b.a.secondPlace - +a.a.secondPlace;
};

export const sortThirdPlace = (a, b, isAsc) => {
  return isAsc ? +a.thirdPlace - +b.thirdPlace : +b.thirdPlace - +a.thirdPlace;
};

export const sortTeamPlace = (a, b, isAsc) => {
  return isAsc ? +a.teamPlace - +b.teamPlace : +b.teamPlace - +a.teamPlace;
};
