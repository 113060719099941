<template>
  <ValidationObserver
    ref="form"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ edited.id ? "Редактирование события" : "Новое событие" }}
        </p>
      </header>

      <section class="modal-card-body">
        <div class="columns">
          <div class="column is-half-desktop">
            <ValidationProvider
              v-slot="{ errors, valid }"
              tag="div"
              class="mb-4"
              rules="required|min:5"
              name="Название"
              slim
            >
              <b-field
                label="Название"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors[0]"
              >
                <b-input v-model="edited.Title" type="text" />
              </b-field>
            </ValidationProvider>

            <FormAutocompleteField
              :resource="countryResource"
              :label="countryLabel"
              :field="countryField"
              :placeholder="countryPlaceholder"
              :selected="country"
              @select="handleSelectCountry"
            />

            <ValidationProvider
              v-slot="{ errors, valid }"
              tag="div"
              class="mb-4"
              rules="required|min:3"
              name="Место проведения"
            >
              <b-field
                label="Место проведения"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors[0]"
              >
                <b-input v-model="edited.locality" type="text" />
              </b-field>
            </ValidationProvider>

            <div class="columns mb-0">
              <ValidationProvider
                v-slot="{ errors, valid }"
                tag="div"
                class="column is-half-desktop"
                rules="required"
                name="Дата начала"
                vid="dateStart"
              >
                <b-field
                  label="Дата начала"
                  :message="errors[0]"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-datepicker
                    v-model="dateStart"
                    placeholder="Выберите дату начала"
                    icon="calendar-today"
                    locale="ru-Ru"
                  >
                    >
                  </b-datepicker>
                </b-field>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors, valid }"
                tag="div"
                class="column is-half-desktop"
                rules="required|isDateGte:dateStart"
                name="Дата окончания"
              >
                <b-field
                  label="Дата окончания"
                  :message="errors[0]"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-datepicker
                    v-model="dateFinish"
                    placeholder="Выберите дату окончания"
                    icon="calendar-today"
                    locale="ru-Ru"
                  >
                    >
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>

            <ValidationProvider name="Виды спорта" tag="div">
              <b-field label="Виды спорта">
                <b-select
                  v-model="selectedSports"
                  multiple
                  native-size="4"
                  placeholder="Выберите вид/виды спорта"
                >
                  <option :value="null">&nbsp;</option>
                  <option
                    v-for="sport in sports"
                    :key="sport.id"
                    :value="sport.id"
                  >
                    {{ sport.Title }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>

          <div class="column is-offset-1-desktop">
            <ValidationProvider
              v-slot="{ errors, valid }"
              rules="ext:jpeg,jpg,webp|size:120"
              name="Фото"
              slim
            >
              <b-field
                class="file is-primary"
                :class="{ 'has-name': !!file, 'is-danger': !valid }"
              >
                <b-upload v-model="file" class="file-label">
                  <span class="file-cta">
                    <b-icon class="file-icon" icon="upload" />
                    <span class="file-label">Выбрать фото</span>
                  </span>
                  <span v-if="file" class="file-name">
                    {{ file.name }}
                  </span>
                  <span
                    v-if="errors[0]"
                    id="errors"
                    class="ml-2 has-text-danger is-size-7"
                    >{{ errors[0] }}</span
                  >
                </b-upload>
              </b-field>
            </ValidationProvider>

            <b-image class="image mt-3" ratio="16by9" :src="imageSrc" />
          </div>
        </div>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-loading="isLoading"
          :is-submit-disabled="invalid"
          :is-show-reset="false"
          @send="validate().then(handleSubmit)"
          @close="handleClose"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import { DATE_FORMAT, Resource } from "@/common/const/common";
import {
  COUNTRY_NOT_SELECTED,
  DATE_DIFF_ERROR,
  SAVE_ERROR,
  SAVE_SUCCESS,
  SPORT_EVENT_ADDED,
} from "@/common/const/message";
import { cloneDeep } from "lodash/lang";
import deleteTimestamps from "@/common/mixins/delete-timestamps";
import FormAutocompleteField from "@/common/components/AppAutocompleteField";
import AppFormButtons from "@/common/components/AppFormButtons";
import { getFormData } from "../utils";

export default {
  name: "SportEventForm",

  components: {
    AppFormButtons,
    FormAutocompleteField,
    ValidationObserver,
    ValidationProvider,
  },

  mixins: [deleteTimestamps],

  props: {
    sportEvent: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      edited: {},
      dateStart: null,
      dateFinish: null,
      isPublished: null,
      selectedSports: [],
      sports: [],

      file: null,
      country: null,
      countryResource: Resource.COUNTRIES,
      countryField: "name",
      countryLabel: "Страна",
      countryPlaceholder: "Название страны",

      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),

    imageSrc() {
      if (this.file && this.file !== this.edited.Images[0]) {
        return URL.createObjectURL(this.file);
      }
      return this.edited.Images[0]
        ? process.env.VUE_APP_API_URL + this.edited.Images[0].url
        : null;
    },
  },

  created() {
    this.edited = cloneDeep(this.sportEvent);
    this.$deleteTimestamps(this.edited);

    if (!this.edited.country) {
      this.country = { id: null };
    }

    this.country = this.edited.country?.id ? this.edited.country : null;

    this.edited.category_sport_type = this.edited.category_sport_type
      ? this.edited.category_sport_type
      : this.user.sportType;

    this.isPublished = !!this.sportEvent.published_at;

    this.dateStart = this.sportEvent.DateStart
      ? new Date(this.sportEvent.DateStart)
      : null;

    this.dateFinish = this.sportEvent.DateFinish
      ? new Date(this.sportEvent.DateFinish)
      : null;

    this.selectedSports = this.sportEvent.category_sports.map(({ id }) => id);
  },

  async mounted() {
    try {
      this.sports = await this.$api[Resource.SPORTS].get(`?_sort=Title:asc`);
    } catch (e) {
      console.log(e);
    }
  },

  beforeDestroy() {
    this.edited = null;
  },

  methods: {
    handleSelectCountry(data) {
      this.country = data;
    },

    async clearImages() {
      if (this.file) {
        await this.$api[Resource.SPORT_EVENTS].put(
          {
            Images: [],
          },
          this.edited.id
        );
      }
    },

    async handleSubmit() {
      this.isLoading = true;
      let message;
      let sportEvent;

      if (this.dateFinish < this.dateStart) {
        this.$notifier.error(DATE_DIFF_ERROR);
        return;
      }
      if (!this.country?.id) {
        this.$notifier.error(COUNTRY_NOT_SELECTED);
        return;
      }

      const edited = { ...this.edited };
      edited.DateStart = this.$dayjs(this.dateStart).format(DATE_FORMAT);
      edited.DateFinish = this.$dayjs(this.dateFinish).format(DATE_FORMAT);

      edited.category_sports = this.selectedSports.length
        ? this.selectedSports.filter((id) => null !== id)
        : [];

      edited.country = this.country.id;
      edited.Location = `${this.country.name}, ${edited.locality}`;
      if (this.file) {
        edited.Cover = true;
      }

      const formData = getFormData(edited, this.file);
      try {
        if (this.edited.id) {
          // удалить старую картинку, если есть новая
          await this.clearImages();

          sportEvent = await this.$api[Resource.SPORT_EVENTS].put(
            formData,
            this.edited.id
          );
          message = SAVE_SUCCESS;
        } else {
          sportEvent = await this.$api[Resource.SPORT_EVENTS].post(formData);
          message = SPORT_EVENT_ADDED;
        }
        this.$notifier.success(message);
        this.$emit("close", sportEvent);
      } catch (e) {
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.isLoading = false;
      }
    },

    handleClose() {
      this.$emit("close");
    },

    ...mapActions({
      initSports: "sports/init",
    }),
  },
};
</script>
