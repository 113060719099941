var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.edited.id ? "Редактирование события" : "Новое событие")+" ")])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half-desktop"},[_c('ValidationProvider',{staticClass:"mb-4",attrs:{"tag":"div","rules":"required|min:5","name":"Название","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Название","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0]}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.edited.Title),callback:function ($$v) {_vm.$set(_vm.edited, "Title", $$v)},expression:"edited.Title"}})],1)]}}],null,true)}),_c('FormAutocompleteField',{attrs:{"resource":_vm.countryResource,"label":_vm.countryLabel,"field":_vm.countryField,"placeholder":_vm.countryPlaceholder,"selected":_vm.country},on:{"select":_vm.handleSelectCountry}}),_c('ValidationProvider',{staticClass:"mb-4",attrs:{"tag":"div","rules":"required|min:3","name":"Место проведения"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Место проведения","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors[0]}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.edited.locality),callback:function ($$v) {_vm.$set(_vm.edited, "locality", $$v)},expression:"edited.locality"}})],1)]}}],null,true)}),_c('div',{staticClass:"columns mb-0"},[_c('ValidationProvider',{staticClass:"column is-half-desktop",attrs:{"tag":"div","rules":"required","name":"Дата начала","vid":"dateStart"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Дата начала","message":errors[0],"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"Выберите дату начала","icon":"calendar-today","locale":"ru-Ru"},model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}},[_vm._v(" > ")])],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"column is-half-desktop",attrs:{"tag":"div","rules":"required|isDateGte:dateStart","name":"Дата окончания"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Дата окончания","message":errors[0],"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"Выберите дату окончания","icon":"calendar-today","locale":"ru-Ru"},model:{value:(_vm.dateFinish),callback:function ($$v) {_vm.dateFinish=$$v},expression:"dateFinish"}},[_vm._v(" > ")])],1)]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":"Виды спорта","tag":"div"}},[_c('b-field',{attrs:{"label":"Виды спорта"}},[_c('b-select',{attrs:{"multiple":"","native-size":"4","placeholder":"Выберите вид/виды спорта"},model:{value:(_vm.selectedSports),callback:function ($$v) {_vm.selectedSports=$$v},expression:"selectedSports"}},[_c('option',{domProps:{"value":null}},[_vm._v(" ")]),_vm._l((_vm.sports),function(sport){return _c('option',{key:sport.id,domProps:{"value":sport.id}},[_vm._v(" "+_vm._s(sport.Title)+" ")])})],2)],1)],1)],1),_c('div',{staticClass:"column is-offset-1-desktop"},[_c('ValidationProvider',{attrs:{"rules":"ext:jpeg,jpg,webp|size:120","name":"Фото","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"file is-primary",class:{ 'has-name': !!_vm.file, 'is-danger': !valid }},[_c('b-upload',{staticClass:"file-label",model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('span',{staticClass:"file-cta"},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"upload"}}),_c('span',{staticClass:"file-label"},[_vm._v("Выбрать фото")])],1),(_vm.file)?_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]):_vm._e(),(errors[0])?_c('span',{staticClass:"ml-2 has-text-danger is-size-7",attrs:{"id":"errors"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()])],1)]}}],null,true)}),_c('b-image',{staticClass:"image mt-3",attrs:{"ratio":"16by9","src":_vm.imageSrc}})],1)])]),_c('footer',{staticClass:"modal-card-foot"},[_c('AppFormButtons',{attrs:{"is-loading":_vm.isLoading,"is-submit-disabled":invalid,"is-show-reset":false},on:{"send":function($event){validate().then(_vm.handleSubmit)},"close":_vm.handleClose}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }