export const EVENTS_PER_PAGE = 20;
export const RESULTS_PER_PAGE = 100;

export const defaultSportEvent = {
  Title: "",
  DateStart: null,
  DateFinish: null,
  Location: "",
  // BodyElements: [{ Text: "", isShowResults: true }],
  published_at: null,
  category_sports: [],
  country: { id: null },
  locality: "",
  Images: [],
};

export const defaultSportResult = {
  id: null,
  event: null,
  date: null,
  category_sport: { id: null },
  sport_discipline: { id: null },
  place: null,
  description: "",
  athletes: [],
  round: null,
  result: "",
};

export const SKATING_TITLE = "Конькобежный спорт";
export const SHORT_TRACK_TITLE = "Шорт-трек";

export const formSkippedFields = ["Images", "id", "_id", "updated_at"];

export const hockeyRounds = [
  { id: "qualification", name: "Квалификация" },
  { id: "circleTournament", name: "Круговой турнир" },
  { id: "groupTournament", name: "Групповой турнир" },
  { id: "quarterFinal", name: "Четвертьфинал" },
  { id: "semiFinal", name: "Полуфинал" },
  { id: "matchFor3rdPlace", name: "Матч за 3 место" },
  { id: "matchForBronze", name: "Матч за бронзовую медаль" },
  { id: "matchFor1rdPlace", name: "Матч за золотую медаль" },
  { id: "theFinal", name: "Финал" },
];

export const summerRounds = [
  { id: "qualification", name: "Квалификация" },
  { id: "preliminaryFights", name: "Предварительные бои" },
  { id: "circleTournament", name: "Круговой турнир" },
  { id: "groupTournament", name: "Групповой турнир" },
  { id: "1_64Final", name: "1/64 финала" },
  { id: "1_32Final", name: "1/32 финала" },
  { id: "1_16Final", name: "1/16 финала" },
  { id: "1_8Final", name: "1/8 финала" },
  { id: "1_4Final", name: "1/4 финала" },
  { id: "quarterFinal", name: "Четвертьфинал" },
  { id: "semiFinal", name: "Полуфинал" },
  { id: "matchFor3rdPlace", name: "Матч за 3 место" },
  { id: "matchForBronze", name: "Матч за бронзовую медаль" },
  { id: "matchFor1rdPlace", name: "Матч за золотую медаль" },
  { id: "theFinal", name: "Финал" },
];

export const teamSports = ["Хоккей", "Керлинг"];
